const data1 = [
      {
        image: "examples/1_1.jpg",
        caption: ""
      },
      {
        image: "examples/2_1.jpg",
        caption: ""
      },
      {
        image: "examples/3_1.jpg",
        caption: ""
      },
      {
        image: "examples/4_1.jpg",
        caption: ""
      },
      {
        image: "examples/5_1.jpg",
        caption: ""
      },
      {
        image: "examples/6_1.jpg",
        caption: ""
      },
      {
        image: "examples/7_1.jpg",
        caption: ""
      },
      {
        image: "examples/8_1.jpg",
        caption: ""
      },
      {
        image: "examples/9_1.jpg",
        caption: ""
      }
    ];

export default data1;
