const data1 = [
    {
        image: "vagonkaPhoto/1.jpg",
        caption: ""
    },
    {
        image: "vagonkaPhoto/2.jpg",
        caption: ""
    },
    {
        image: "vagonkaPhoto/3.jpg",
        caption: ""
    },
    {
        image: "vagonkaPhoto/4.jpg",
        caption: ""
    },
    {
        image: "vagonkaPhoto/5.jpg",
        caption: ""
    },
    {
        image: "vagonkaPhoto/6.jpg",
        caption: ""
    },{
        image: "vagonkaPhoto/7.jpg",
        caption: ""
    }
];

export default data1;
