const data1 = [
    {
        image: "plumbingPhoto/1.jpg",
        caption: ""
    },{
        image: "plumbingPhoto/2.jpg",
        caption: ""
    },
    {
        image: "plumbingPhoto/3.jpg",
        caption: ""
    },
    {
        image: "plumbingPhoto/4.jpg",
        caption: ""
    }
];

export default data1;
