const data2 = [
    {
        image: "examples/1_2.jpg",
        caption: ""
    },
    {
        image: "examples/2_2.jpg",
        caption: ""
    },
    {
        image: "examples/3_2.jpg",
        caption: ""
    },
    {
        image: "examples/4_2.jpg",
        caption: ""
    },
    {
        image: "examples/5_2.jpg",
        caption: ""
    },
    {
        image: "examples/6_2.jpg",
        caption: ""
    }
];
export default data2;