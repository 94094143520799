const data1 = [
    {
        image: "parilkiPhoto/6.jpg",
        caption: ""
    },{
        image: "parilkiPhoto/7.jpg",
        caption: ""
    },
    {
        image: "parilkiPhoto/1.jpg",
        caption: ""
    },
    {
        image: "parilkiPhoto/2.jpg",
        caption: ""
    },
    {
        image: "parilkiPhoto/3.jpg",
        caption: ""
    },
    {
        image: "parilkiPhoto/4.jpg",
        caption: ""
    },
    {
        image: "parilkiPhoto/5.jpg",
        caption: ""
    }
];

export default data1;
